import React, { FC, useEffect, useRef } from "react";

import { log } from "../../utils";

export interface SearchResponseAudioProps {
    readonly audio: string;
    onAudioChange(isOn: boolean): void;
}

export const SearchResponseAudio: FC<SearchResponseAudioProps> = props => {
    const { audio, onAudioChange } = props;

    const audioRef = useRef(null);

    useEffect(() => {
      const audioElement = audioRef.current as unknown as HTMLAudioElement;
  
      const handleAudioEnd = () => {
        log("Audio finished playing");
        onAudioChange(false);
      };
  
      const handleAudioPlay = () => {
        log("Audio started playing");
        onAudioChange(true);
      };
  
      if (audioElement) {
        audioElement.addEventListener("ended", handleAudioEnd);
        audioElement.addEventListener("play", handleAudioPlay);
      }

      return () => {
        if (audioElement) {
            audioElement.removeEventListener("ended", handleAudioEnd);
            audioElement.removeEventListener("play", handleAudioPlay);
        }
      };
    }, [audio, onAudioChange]);

    return (
        <audio ref={audioRef} autoPlay={true} src={audio} />
    );
};