import { AddressAutocompleteParameters } from "stentor-models";

import { err } from ".";

interface AddressAutocompleteParametersWithInput extends AddressAutocompleteParameters {
    input: string;
}

export interface AddressAutocompleteConfig {
    apiKey?: string;
    baseUrl?: string;
}

export interface AddressAutocompleteQuery {
    input: string;
    componentRestrictions?: {
        country: "US";
    };
    params?: AddressAutocompleteParameters;
}

export interface AddressAutocompleteResponse {
    predictions: PlaceType[];
    status: "INVALID_REQUEST" | "OK" | "ZERO_RESULTS";
}

export interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

export interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

/**
 * Cleans the params in preparation for URLSearchParams
 * 
 * @param obj 
 * @returns 
 */
function cleanParams(obj: AddressAutocompleteParametersWithInput): Record<string, string> {
    const record: Record<string, string> = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            record[key] = String(obj[key]);
        }
    }
    return record;
}

function constructUrl(baseUrl: string, queryParams: AddressAutocompleteParametersWithInput): string {

    const url = new URL(baseUrl);
    const params = new URLSearchParams(cleanParams(queryParams));
    url.search = params.toString();
    return url.toString();
}

// Example:
// https://maps.googleapis.com/maps/api/place/autocomplete/json
// ?input=7009%20Spur%Road
// &components=country:us
// &key=AIzaSyCBWVqsLgwhFoEvug4ZHmkj24qE89Gncbc

/**
 * A service that interacts with a Google Places Autocomplete compatible API.
 */
export class AddressAutocompleteService {
    private readonly baseUrl: string;
    private readonly key?: string;

    constructor(config: AddressAutocompleteConfig) {
        this.baseUrl = config.baseUrl || "https://places.xapp.ai"
        this.key = config.apiKey;
    }

    public getPlacePredictions(
        query: AddressAutocompleteQuery,
        callback: (results?: readonly PlaceType[]) => void
    ) {

        const queryParams: AddressAutocompleteParametersWithInput = query.params ? { ...query.params, input: query.input } : { input: query.input };

        // existing
        if (query.componentRestrictions) {
            const restrictions = Object.entries(query.componentRestrictions)
                .map(([key, value]) => `${key}:${value}`)
                .join(",");

            queryParams.components = restrictions;
        }

        // old default behavior was limiting to US
        if (!query.params && !queryParams.components) {
            queryParams.components = "country:us";
        }

        if (this.key) {
            queryParams.key = this.key;
        }

        const url = constructUrl(`${this.baseUrl}/maps/api/place/autocomplete/json`, queryParams);

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`getPlacePredictions: HTTP error! Status: ${response.status}`);
                }

                return response.json();
            })
            .then((data: AddressAutocompleteResponse) => {
                if (data.status === "INVALID_REQUEST") {
                    err(`getPlacePredictions: INVALID_REQUEST. Url: ${url}`);
                }

                callback(data.predictions);
            })
            .catch((error) => err("getPlacePredictions: Fetch error:", error));
    }
}
