import React, { FC, useReducer, PropsWithChildren } from "react";
import { RootReducer } from "./reducers";
import { SearchState } from "./SearchState";
import { SearchStore } from "./SearchStore";
import { DEFAULT_SEARCH_STATE, SearchStoreContext } from "./SearchStoreContext";

export interface SearchStoreProviderProps {
    readonly initialState?: SearchState;
}

export const SearchStoreProvider: FC<PropsWithChildren<SearchStoreProviderProps>> = props => {

    const reducer: SearchStore = useReducer(RootReducer, props.initialState || DEFAULT_SEARCH_STATE);

    return (
        <SearchStoreContext.Provider value={reducer}>
            {props.children}
        </SearchStoreContext.Provider>
    );
};