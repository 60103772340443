import React, { FC } from "react";
import { SuggestionTypes } from "stentor-models";
import { SuggestedAction } from "../SuggestedAction";
import styles from "./SuggestedActions.module.scss";


export interface SuggestedActionsProps {
    readonly actions: readonly SuggestionTypes[];
    onActionClick(action: SuggestionTypes): void;
}

export const SuggestedActions: FC<SuggestedActionsProps> = props => {
    const { actions, onActionClick } = props;
    return (
        <div className={styles.xapp_search_suggested_actions}>
            <div className={styles.xapp_search_suggested_actions__actions}>
                {actions.map((act, index) => (
                    <SuggestedAction
                        key={index}
                        action={act}
                        className={styles.xapp_search_suggested_actions__item}
                        onClick={onActionClick}
                    />))}
            </div>
        </div>
    );
};