// Note: This is great code that we should move to stentor-utils to help reduce URLs
// Right now I just copy and pasted this from the widget channel

declare type Parser = (rest: string, all: string) => number;

function firstIndex(val: string, patterns: readonly string[]) {
    let res = -1;
    for (const pattern of patterns) {
        const index = val.indexOf(pattern);
        if (index >= 0) {
            if (res < 0 || ((res >= 0) && (index < res))) {
                res = index;
            }
        }
    }
    return res;
}

const schemeParser: Parser = rest => {
    const next = rest.indexOf("://");
    if (next < 0) return next;
    return next + 3;
};

const domainEnds: readonly string[] = [":", "/"];
const portEnds: readonly string[] = ["/"];
const pathEnds: readonly string[] = ["?", "#"];
const queryEnds: readonly string[] = ["#"];

const domainParser: Parser = rest => {
    return firstIndex(rest, domainEnds);
};

const portParser: Parser = rest => {
    if (rest.charAt(0) !== ":") {
        return -1;
    }
    return firstIndex(rest, portEnds);
};

const pathParser: Parser = rest => {
    const res = firstIndex(rest, pathEnds);
    if (res >= 0) {
        return res;
    }
    return rest.length;
};

const queryParser: Parser = rest => {
    return firstIndex(rest, queryEnds);
};

const parsers: Parser[] = [
    schemeParser,
    domainParser,
    portParser,
    pathParser,
    queryParser
];

function parse(val: string): string[] {
    const parts: string[] = [];
    let rest = val;
    for (const parser of parsers) {
        const newIndex = Math.max(0, parser(rest, val));
        const part = rest.substring(0, newIndex);
        parts.push(part);
        rest = rest.substring(newIndex);
    }
    if (rest) {
        parts.push(rest);
    }
    return parts;
}

const ellipsis = "/...";

export function reduceLink(url: string, maxLength: number): string {
    if (!url) return url;

    if (url.length < maxLength) return url;

    const parts = parse(url); // [scheme, domain, port, path, query, hash]

    function getText() {
        return parts.join("");
    }

    function overflows() {
        return getText().length > maxLength;
    }

    function tryReduce(index: number) {
        if (overflows()) {
            parts[index] = "";
        }
    }

    if (parts[2] === ":80" && parts[0] === "http://") {
        tryReduce(2);
    }
    if (parts[2] === ":443" && parts[0] === "https://") {
        tryReduce(2);
    }

    tryReduce(5);
    tryReduce(4);
    tryReduce(2);
    tryReduce(0);

    if (overflows()) {
        const path = parts[3];
        const allButPathLength = getText().length - path.length;
        const available = maxLength - allButPathLength;
        if (available < 0) {
            parts[3] = "";
        } else if (available < path.length) {
            const newPathStart = path.length - available + ellipsis.length;
            parts[3] = ellipsis + path.substring(newPathStart)
        }
    }

    return getText();
}
