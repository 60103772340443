import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";

import {
    FormCardInput,
    FormChipsInput,
    FormDateInput,
    FormDateRangeInput,
    FormDropdownInput,
    FormFieldTextAddressInput,
    FormStep,
    FormTextInput,
} from "stentor-models";

import { addContextPrefix, shallowEqualBooleanMap } from "../../utils/comparator";
import { isFormCheckInput, isFormChipsInput, isFormDateInput, isFormTextInput } from "../../utils/guards";
import { joinWithAnd, reformatDate, replaceValues } from "../../utils/templating";
import { SearchWidgetConfigContext } from "../../utils/SearchWidgetConfigContext";
import { useSearchState } from "../../store";

import FormFieldSelect from "../FormFieldChips/FormFieldSelect";
import { FormFieldCard } from "../FormFieldCard";
import { FormFieldChips } from "../FormFieldChips";
import { FormFieldTextInput } from "../FormFieldTextInput";
import { FormFieldDropdown } from "../FormFieldDropdown";
import { FormFieldDate } from "../FormFieldDate";
import { FormFieldDateRange } from "../FormFieldDateRange";
import { FormFieldTextInputAddress } from "../FormFieldTextInputAddress";

import {
    FieldValidatorResult,
    fieldErrorsEqual,
    formatUSPhoneNumber,
    isEmpty,
    isEmptyString,
    validateEmail,
    validatePhoneNumber
} from "./validators";

import styles from "./FormStepDisplay.module.scss";

export interface StepData {
    current?: { [key: string]: unknown }
}

export interface FormStepDisplayProps {
    /**
     * Step data
     */
    readonly formStep: FormStep;
    /**
     * When the user hits the next button.  
     * 
     * If submit is true, then the form should be submitted.
     */
    onNextStep: ((submit?: boolean) => void) | undefined;
    /**
     * When the user hits the previous button
     */
    onPrevStep: (() => void) | undefined;
    /**
     * Data from the step 
     */
    onData: (data: StepData) => void;
    /**
     * Data from the step but split up into key, value pairs.
     * 
     * For example, {"name": "John Smith"}
     */
    onDataWithLabels: (data: object) => void;

    getData: () => object;
    /**
     * Returns the data needed for the form   
     * 
     * @returns 
     */
    getDataWithLabels: () => object;
    /**
     * Is the form loading
     */
    loading: boolean;
}

export const FormStepDisplay: FC<FormStepDisplayProps> = (props) => {

    // TODO: Figure out initial data
    const stepData = useRef<StepData>({});

    const fieldTests = useRef({});

    const [fieldConditions, setFieldConditions] = useState<{ [name: string]: boolean }>({});

    const [fieldErrors, setFieldErrors] = useState<{ [name: string]: FieldValidatorResult }>({});

    const { formStep, onNextStep: nextStep, onPrevStep: prevStep, onData: handleData, onDataWithLabels: handleDataWithLabels, getData, getDataWithLabels, loading } = props;

    const checkConditions = useCallback(() => {
        const newConditions = {};

        Object.entries(fieldTests.current).forEach(([name, f]) => {
            // eslint-disable-next-line @typescript-eslint/ban-types
            newConditions[name] = (f as Function).call({ ...getData(), ...stepData.current });
        });

        if (!shallowEqualBooleanMap(newConditions, fieldConditions)) {
            setFieldConditions(newConditions);
        }
    }, [fieldConditions, getData]);

    useEffect(() => {

        // check for initial data and look for conditions

        for (const field of formStep.fields) {

            if (field.type === "CHIPS") {
                // look for preselected chips
                const chipsSelectedFromProps = (field as FormChipsInput).items.filter((item) => item.selected).map((item) => item.id);
                stepData.current[field.name] = chipsSelectedFromProps;
            }

            if (field.condition) {
                const conditionWithThis = addContextPrefix(field.condition);

                // eslint-disable-next-line no-new-func
                fieldTests.current[field.name] = new Function(`return (${conditionWithThis});`);
            }
        }

        checkConditions();
    }, [checkConditions, formStep.fields]); // once

    const validate: () => boolean = () => {
        const errors = {};
        let isValid = true;

        const corrections = {};

        formStep.fields.forEach((field) => {
            let result: FieldValidatorResult = { valid: true };

            // Validate only if visible and we have data
            if (fieldConditions[field.name] !== false && stepData.current.hasOwnProperty(field.name)) {
                if (isFormTextInput(field)) {
                    if (field.mandatory === true && isEmptyString(stepData.current[field.name])) {
                        result = { valid: false, message: field.mandatoryError || "This field is mandatory" };
                    } else {
                        switch (field.format) {
                            case "EMAIL":
                                // if it is empty, we don't need to validate 
                                if (isEmptyString(stepData.current[field.name])) {
                                    result = { valid: true };
                                } else {
                                    result = validateEmail(stepData.current[field.name]);
                                }
                                break;

                            case "PHONE":
                                if (isEmptyString(stepData.current[field.name])) {
                                    result = { valid: true };
                                } else {
                                    result = validatePhoneNumber(stepData.current[field.name]);

                                    if (result.valid) {
                                        const formattedNumber = formatUSPhoneNumber(stepData.current[field.name]);
                                        if (formattedNumber !== stepData.current[field.name]) {
                                            corrections[field.name] = formattedNumber;
                                        }
                                    }
                                }
                                break;

                            default:
                                break;
                        }
                    }
                } else if (isFormChipsInput(field)) {
                    if (field.minRequired) {
                        if (stepData.current[field.name].length < field.minRequired) {
                            result = { valid: false, message: field.mandatoryError || `Please select at least ${field.minRequired} option(s)` };
                        }
                    }

                    if (field.maxAllowed) {
                        if (stepData.current[field.name].length > field.maxAllowed) {
                            result = { valid: false, message: field.mandatoryError || `Please select no more than ${field.maxAllowed} option(s)` };
                        }
                    }

                    if (field.mandatory) {
                        if (stepData.current[field.name].length !== 1) {
                            result = { valid: false, message: field.mandatoryError || "Please select one option" };
                        }
                    }
                } else if (isFormDateInput(field)) {
                    if (field.mandatory) {
                        if (stepData.current[field.name] == null) { // checks both undefined and nulls
                            result = { valid: false, message: field.mandatoryError || "Please select a day" };
                        }
                    }
                } else if (isFormCheckInput(field)) {
                    if (field.mandatory) {
                        if (stepData.current[field.name].length !== 1) {
                            result = { valid: false, message: field.mandatoryError || "This field is mandatory" };
                        }
                    }
                }
            }

            isValid = isValid && result.valid;
            errors[field.name] = result;
        });

        // Only if we corrected
        if (!isEmpty(corrections)) {
            stepData.current = { ...stepData.current, ...corrections };
        }

        // Only if changed
        if (!fieldErrorsEqual(fieldErrors, errors)) {
            setFieldErrors(errors);
        }

        return isValid;
    };

    const continueToNext = () => {
        if (!validate()) {
            return;
        }

        pushData(stepData.current);

        if (nextStep) {
            nextStep(formStep.nextAction === "submit");
        }
    };

    const pushData = (data: object) => {
        handleData(data);

        // Now create the equivalent with string representation for templating
        const dataWithLabels: { [name: string]: string } = {};

        for (const fieldName of Object.keys(data)) {
            const value = data[fieldName];

            const formField = formStep.fields.find((field) => field.name === fieldName);

            if (formField?.type === "CHIPS" && value !== undefined) {
                const valueLabels: string[] = [];

                (value as string[]).forEach((s) => {
                    const item = (formField as FormChipsInput).items.find((chip) => chip.id === s);
                    if (item) {
                        valueLabels.push(item.label);
                    }
                });

                dataWithLabels[fieldName] = joinWithAnd(valueLabels);
            } else if (formField?.type === "DATE" && value !== undefined) {
                dataWithLabels[fieldName] = reformatDate(value);
            }
            else {
                dataWithLabels[fieldName] = value;
            }
        }

        handleDataWithLabels(dataWithLabels);
    };

    const goBackToPrevious = () => {
        pushData(stepData.current);

        if (prevStep) {
            prevStep();
        }
    };

    const isNextDisabled = () => {

        // for each of the fields, check...
        // 1. for the required ones, do we have entries and are they are valid
        // 2. for the optional ones, if they have any content, make sure they are value

        for (const key in fieldErrors) {
            // make sure we are only looking at required fields
            if (fieldConditions[key] === false) {
                continue;
            }
            if (!fieldErrors[key].valid) {
                return true;
            }
        }
        return false;
    };

    const onFieldChange = (name: string, value: string | string[] | object | undefined) => {
        const isFirst = !stepData.current.hasOwnProperty(name);

        if (!isFirst && stepData.current[name] === value) {
            return;
        }

        stepData.current = { ...stepData.current, [name]: value };

        checkConditions();

        if (!isEmpty(fieldErrors)) {
            validate();
        }
    };

    const config = useContext(SearchWidgetConfigContext);

    const { context } = useSearchState();

    checkConditions();

    return (
        <div>
            {!!formStep && formStep.title && (
                <div className={styles.xapp_formfield_title}>
                    <p>{formStep.title}</p>
                </div>
            )}

            <div>
                {!!formStep &&
                    formStep.fields.map((field, index) => {
                        let fieldComponent: JSX.Element | null;

                        switch (field.type) {
                            case "TEXT":
                                if ((field as FormFieldTextAddressInput).format === "ADDRESS") {
                                    fieldComponent = (
                                        <FormFieldTextInputAddress
                                            key={field.name}
                                            field={field as FormFieldTextAddressInput}
                                            onFieldChange={onFieldChange}
                                            value={stepData.current[field.name]}
                                            errorMessage={fieldErrors[field.name]?.message}
                                            required={field.mandatory}
                                            googleMapsApiKey={config.googleMapsApiKey}
                                            mapsBaseUrl={config.mapsBaseUrl}
                                        />
                                    );
                                } else {
                                    fieldComponent = (
                                        <FormFieldTextInput
                                            key={field.name}
                                            field={field as FormTextInput}
                                            onFieldChange={onFieldChange}
                                            value={stepData.current[field.name]}
                                            errorMessage={fieldErrors[field.name]?.message}
                                            required={field.mandatory}
                                        />
                                    );
                                }
                                break;
                            case "DROPDOWN":
                                fieldComponent = (
                                    <FormFieldDropdown
                                        key={field.name}
                                        field={field as FormDropdownInput}
                                        onFieldChange={onFieldChange}
                                    />
                                );
                                break;
                            case "CHECK":
                                fieldComponent = (
                                    <FormFieldSelect
                                        key={field.name}
                                        field={field as FormChipsInput}
                                        onFieldChange={onFieldChange}
                                        errorMessage={fieldErrors[field.name]?.message}
                                    />
                                );
                                break;
                            case "CHIPS":
                                fieldComponent = (
                                    <FormFieldChips
                                        key={field.name}
                                        field={field as FormChipsInput}
                                        onFieldChange={onFieldChange}
                                        errorMessage={fieldErrors[field.name]?.message}
                                    />
                                );
                                break;
                            case "DATE":

                                const busyDays = context?.busyDays;

                                fieldComponent = (
                                    <FormFieldDate
                                        key={field.name}
                                        field={field as FormDateInput}
                                        onFieldChange={onFieldChange}
                                        errorMessage={fieldErrors[field.name]?.message}
                                        busyDays={busyDays}
                                        loading={loading}
                                    />
                                );
                                break;
                            case "DATERANGE":
                                fieldComponent = (
                                    <FormFieldDateRange
                                        key={field.name}
                                        field={field as FormDateRangeInput}
                                        onFieldChange={onFieldChange}
                                    />
                                );
                                break;

                            case "CARD":
                                fieldComponent = (
                                    <FormFieldCard
                                        key={field.name}
                                        field={
                                            replaceValues(
                                                field,
                                                getDataWithLabels()
                                            ) as FormCardInput
                                        }
                                        onFieldChange={onFieldChange}
                                    />
                                );
                                break;

                            default:
                                fieldComponent = null;
                        }

                        return (
                            <div
                                key={index}
                                className={`${styles.xapp_formfield} ${fieldConditions[field.name] !== false ? styles.active : ""}`}>
                                {fieldComponent}
                            </div>
                        );
                    })}
            </div>

            <div className={styles.xapp_form_nav_buttons}>
                {prevStep === undefined && <div className={styles.xapp_dummy_button}></div>}

                {!!prevStep && formStep.previousAction !== "omit" && (
                    <Button
                        className={styles.xapp_primary_button}
                        onClick={goBackToPrevious}
                        // color="primary"
                        variant="contained">
                        {formStep.previousLabel || "Previous"}
                    </Button>
                )}

                {!!nextStep && formStep.nextAction !== "omit" && (
                    <Button
                        className={
                            isNextDisabled()
                                ? styles.xapp_disabled_button
                                : formStep.nextLabel
                                    ? styles.xapp_secondary_button
                                    : styles.xapp_primary_button
                        }
                        onClick={continueToNext}
                        // color={formStep.nextLabel ? "secondary" : "primary"}
                        variant="contained">
                        {formStep.nextLabel || "Next"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default FormStepDisplay;


