
export const EMAIL_REGEX = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$";

// Returns current timestamp
function getCurrentDateString() {
  return (new Date()).toISOString() + " ::";
}

type LogParam = unknown;

export function log(...args: readonly LogParam[]): void {
  if (window.xaLogLevel === "debug") {
    console.log(getCurrentDateString(), ...args);
  }
}

export function err(...args: readonly LogParam[]): void {
  console.error(getCurrentDateString(), ...args);
}

export const logOnce = (key: string, ...args: readonly LogParam[]): void => {
  const map = {};
  if (!map[key]) {
    map[key] = true;
    log(...args);
  }
}

export * from "./useIsMounted";