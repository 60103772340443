import React, { FC, useEffect, useRef, useState } from "react";

import { FormStep, MultistepForm } from "stentor-models";

import { FormActionResponseData } from ".";
import { FormStepDisplay } from "../FormStepDisplay";

import styles from "./SearchResponseFormDisplay.module.scss";
import { SimpleNavigationHeader } from "../SimpleNavigationHeader";
import { err } from "../../utils";
import { addContextPrefix } from "../../utils/comparator";

export interface SearchResponseFormDisplayProps {
    readonly form: MultistepForm;
    readonly loading: boolean;
    onActionResponse(data: unknown, action: string): void;
    isShowCase?: boolean;
}

// TODO: Rename this to ResponseFormDisplay or FormDisplay
export const SearchResponseFormDisplay: FC<SearchResponseFormDisplayProps> = (props) => {
    const { form, onActionResponse, isShowCase, loading } = props;

    // TODO: const title = form?.title;

    const [currentStep, setCurrentStep] = useState(0);
    // const [submitted, setSubmitted] = useState(false);

    const fieldTests = useRef({});

    useEffect(() => {
        for (const step of form.steps) {
            if (step.condition) {
                const conditionWithThis = addContextPrefix(step.condition);

                // eslint-disable-next-line no-new-func
                fieldTests.current[step.name] = new Function(`return (${conditionWithThis});`);
            }
        }
    }, [form.steps]);

    useEffect(() => {
        // Check if currentIndex is out of bounds
        if (currentStep < 0 || currentStep >= form?.steps?.length) {
            setCurrentStep(0);
        }
    }, [form.steps, currentStep]);

    const formData = useRef({});
    const formDataWithLabels = useRef({});

    const nextStep = (submit?: boolean) => {
        for (let i = currentStep + 1; i < form.steps.length; i++) {
            const step = form.steps[i];

            const test = fieldTests.current[step.name];

            if (test) {
                // eslint-disable-next-line @typescript-eslint/ban-types
                if ((test as Function).call({ ...formData.current })) {
                    setCurrentStep(i);
                    break;
                } else {
                    continue;
                }
            } else {
                setCurrentStep(i);
                break;
            }
        }

        if (!!submit) {
            onSubmitForm();
        }
    }

    const prevStep = () => {
        for (let i = currentStep - 1; i >= 0; i--) {
            const step = form.steps[i];

            const test = fieldTests.current[step.name];

            if (test) {
                // eslint-disable-next-line @typescript-eslint/ban-types
                if ((test as Function).call({ ...formData.current })) {
                    setCurrentStep(i);
                    break;
                } else {
                    continue;
                }
            } else {
                setCurrentStep(i);
                break;
            }
        }
    }

    const handleData = (data: object) => {
        formData.current = { ...formData.current, ...data };
    };

    const getData = () => {
        return formData.current;
    };

    const handleDataWithLabels = (data: object) => {
        formDataWithLabels.current = { ...formDataWithLabels.current, ...data };
    };

    const getDataWithLabels = () => {
        return formDataWithLabels.current;
    };

    const onSubmitForm = () => {
        const data: FormActionResponseData = {
            result: formData.current,
            form: form.name,
            step: form.steps[step].name,
            final: !!form.steps[step].final
        };
        onActionResponse(data, "FORM_SUBMIT");
    };

    const onHeaderClick = (step: string) => {
        const condition = (formStep: FormStep) => formStep.name === step;
        const index = form.steps.findIndex(condition);

        if (index === -1) {
            err(`Header click: Cannot find step to jump to: ${step}`);
            return;
        }

        setCurrentStep(index);
    };

    let step = currentStep;

    // Safety
    if (currentStep < 0 || currentStep >= form?.steps?.length) {
        step = 0;
    }

    return (
        <div>
            {form.header.length > 0 && (
                <div className={!!isShowCase ? styles.xapp_header_container_showcase : styles.xapp_header_container}>
                    <SimpleNavigationHeader
                        items={form.header}
                        onHeaderClick={onHeaderClick}
                        labelHeader={form.labelHeader}
                        stepName={form.steps[step].name}
                        isShowCase={isShowCase}
                    />
                </div>
            )}
            <div>
                {form.steps.map((formStep, index) => (
                    <div
                        key={index}
                        className={`${styles.xapp_formstep} ${form.steps[step].name === formStep.name ? styles.active : ""
                            }`}>
                        <FormStepDisplay
                            formStep={formStep}
                            onData={handleData}
                            onDataWithLabels={handleDataWithLabels}
                            onNextStep={step < form.steps.length ? nextStep : undefined}
                            onPrevStep={step > 0 ? prevStep : undefined}
                            getData={getData}
                            getDataWithLabels={getDataWithLabels}
                            loading={loading}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
