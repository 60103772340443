import React, { FC } from "react";
import {
    FormWidgetDropdownTheme,
    FormWidgetTextTheme,
    FormWidgetTheme,
    FormWidgetCardTheme,
    FormWidgetCheckboxTheme,
    FormWidgetChipsTheme,
    FormWidgetDateTheme,
    FormWidgetSideButtonTheme
} from "@xapp/stentor-form-widget-channel";

import { err } from "../../utils";

export interface WidgetStylesheetProps {
    readonly theme?: FormWidgetTheme;
}

/**
 * Injects the styles
 * 
 * @param props 
 * @returns 
 */
export const WidgetStylesheet: FC<WidgetStylesheetProps> = (props) => {
    const { theme } = props;

    const stylesContent = buildStyleContent(theme);
    return <style>{stylesContent}</style>;
};

export function buildStyleContent(widgetTheme?: FormWidgetTheme): string {
    const theme: FormWidgetTheme = widgetTheme || {};

    return `
:root {
    ${buildVariables(
        withPrefix(
            "\t--xapp-form-widget-",
            union(
                single("background-color", theme?.backgroundColor),

                single("header-background-color", theme?.headerBackgroundColor),
                single("header-text-color", theme?.headerTextColor),

                single("primary-button-color", theme?.primaryButtonColor),
                single("primary-button-text-color", theme?.primaryButtonTextColor),
                single("secondary-button-color", theme?.secondaryButtonColor),
                single("secondary-button-text-color", theme?.secondaryButtonTextColor),

                withPrefix("text-", union(getTextStyle(theme?.text))),
                withPrefix("dropdown-", union(getDropdownStyle(theme?.dropdown))),
                withPrefix("checkbox-", union(getCheckboxStyle(theme?.checkbox))),
                withPrefix("chips-", union(getChipsStyle(theme?.chips))),
                withPrefix("date-", union(getDateStyle(theme?.date))),
                withPrefix("card-", union(getCardStyle(theme?.card))),
                withPrefix("side-button-", union(getSideButtonStyle(theme?.sideButton)))
            )
        )
    )}
}

`;
}

function* getSideButtonStyle(style?: FormWidgetSideButtonTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["background-color-dark", darken(style.backgroundColor, 10)];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
        yield ["top", style.top];
        yield ["min-length", style.minLength];
    }
}

function* getTextStyle(style?: FormWidgetTextTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["background-color-dark", darken(style.backgroundColor, 3)];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
    }
}

function* getDropdownStyle(style?: FormWidgetDropdownTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
    }
}

function* getCheckboxStyle(style?: FormWidgetCheckboxTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
    }
}

function* getChipsStyle(style?: FormWidgetChipsTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["background-color-selected", style.backgroundColorSelected];
        yield ["background-color-dark", darken(style.backgroundColor, 20)];
        yield ["color", style.color];
        yield ["color-selected", style.colorSelected];
        yield ["font-size", style.fontSize];
    }
}

function* getDateStyle(style?: FormWidgetDateTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["background-color-selected", style.backgroundColorSelected];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
    }
}

function* getCardStyle(style?: FormWidgetCardTheme): StyleGenerator {
    if (style) {
        yield ["background-color", style.backgroundColor];
        yield ["color", style.color];
        yield ["font-size", style.fontSize];
    }
}

declare type StyleGenerator = Generator<[string, string | undefined], void, never>;

// function* empty(): StyleGenerator {}

function buildVariables(gen: StyleGenerator) {
    let result = gen.next();
    let res = "";
    while (result.done === false) {
        const [key, value] = result.value;
        if (value !== undefined && value !== null && value !== "") {
            res += `${key}: ${value};\n\r`;
        }
        result = gen.next();
    }
    return res;
}

function* withPrefix(prefix: string, gen: StyleGenerator): StyleGenerator {
    let result = gen.next();
    while (!result.done) {
        yield [`${prefix}${result.value[0]}`, result.value[1]];
        result = gen.next();
    }
}

function* union(...children: StyleGenerator[]): StyleGenerator {
    for (const child of children) {
        let result = child.next();
        while (result.done === false) {
            yield result.value;
            result = child.next();
        }
    }
}

function* single(key: string, value: string | undefined): StyleGenerator {
    yield [key, value];
}

function darken(colorIn: string | undefined, amount: number) {
    if (!colorIn) {
        return colorIn;
    }

    let r: number | undefined, g: number | undefined, b: number | undefined, a: number | undefined;
    let colorOut;

    try {
        // Parse the color format
        if (colorIn.startsWith('#')) {
            // Hex format
            const hexMatch = colorIn.match(/\w\w/g);
            if (hexMatch) {
                [r, g, b] = hexMatch.map(x => parseInt(x, 16));
            }
        } else if (colorIn.startsWith('rgb')) {
            // RGB or RGBA format
            const rgba = parseRgb(colorIn);
            if (rgba) {
                ({ r, g, b, a } = rgba);
            }
        }

        if (r === undefined || g === undefined || b === undefined) {
            err(`Darken: Unsupported color format: ${colorIn}`);
            return colorIn;
        }

        if (a !== undefined) {
            // Just darkened backdrop?
            if (r === 0 && g === 0 && b === 0) {
                a = a + amount / 100;
            } else {
                // Adjust luminance for RGBA
                const luminance = 0.3 * r + 0.59 * g + 0.11 * b;
                const newLuminance = luminance * (1 - amount / 100);
                const ratio = newLuminance / luminance;
                r = Math.round(r * ratio);
                g = Math.round(g * ratio);
                b = Math.round(b * ratio);
            }

            colorOut = `rgba(${r}, ${g}, ${b}, ${a})`;
        } else {
            // Darken the color for RGB or Hex
            r = Math.max(0, r - amount);
            g = Math.max(0, g - amount);
            b = Math.max(0, b - amount);

            colorOut = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
        }
    } catch (e) {
        err(`Darken: Unsupported color format: ${colorIn} - Error: ${e}`);
    }

    return colorOut;
}

function parseRgb(colorString: string): { r: number, g: number, b: number, a?: number } | null {
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/;
    const rgbRegex = /^rgb?\((\d+),\s*(\d+),\s*(\d+)\)$/;

    let match: string[] | null;

    // eslint-disable-next-line no-cond-assign
    if (match = colorString.match(rgbaRegex)) {
        const r = parseInt(match[1]);
        const g = parseInt(match[2]);
        const b = parseInt(match[3]);
        const a = parseFloat(match[4]);

        return { r, g, b, a };
        // eslint-disable-next-line no-cond-assign
    } else if (match = colorString.match(rgbRegex)) {
        const r = parseInt(match[1]);
        const g = parseInt(match[2]);
        const b = parseInt(match[3]);

        return { r, g, b };
    } else {
        return null;
    }
}