import template from "lodash.template";

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const template = require("lodash.template");

const customFormatters = {
    dateTime: (o: string) => {
        return reformatDate(o);
    },
};

export function replaceValues<T>(o: T, data: object): T {
    try {
        const compiled = template(JSON.stringify(o), { interpolate: /#{([\s\S]+?)}/g, imports: customFormatters });
        const json = compiled(data);
        const escaped = json.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        return JSON.parse(escaped);
    } catch (error) {
        // Let it go
        // console.error(`Template replace failed: ${error}`);
    }

    return o;
}

function getOrdinalSuffix(number: number) {
    if (number >= 11 && number <= 13) {
      return 'th';
    }
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  
  export function reformatDate(inputDate: string | number | Date) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
  
    return `${month} ${day}${ordinalSuffix}, ${year}`;
  }
  
  export function joinWithAnd(arr: string[]) {
    if (arr.length === 0) {
      return '';
    } else if (arr.length === 1) {
      return arr[0];
    } else if (arr.length === 2) {
      return arr.join(' and ');
    } else {
      const lastElement = arr.pop(); // Remove the last element
      const joinedString = arr.join(', ') + ', and ' + lastElement;
      return joinedString;
    }
  }

// Tests

// const data = {
//     foo: 3,
//     d: new Date().toDateString(),
//     name: "Joe Bloe",
//     a: ["x", "y", "z"]
// };

// const T = {
//     // eslint-disable-next-line no-template-curly-in-string
//     x: "${foo} ${a}"
// }

// console.log(JSON.stringify(replaceValues(T, data)));