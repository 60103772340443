import React, { FC } from "react";
import { List } from "stentor-models";
import { Carousel } from "../Carousel";

export interface SearchResponseCarouselDisplayProps {
    readonly carousel: List;
}

export const SearchResponseCarouselDisplay: FC<SearchResponseCarouselDisplayProps> = props => {
    const { carousel } = props;
    return (
        <div>
            <Carousel list={carousel} />
        </div>
    );
};
