import React, { FC, useEffect, useState } from "react";
import { FormSelectInput } from "stentor-models";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { FormFieldProps } from "../SearchResponseFormDisplay";
import FormHelperText from "@mui/material/FormHelperText";

import styles from './FormFieldSelect.module.scss';

export interface FormSelectProps extends FormFieldProps {
    readonly field: FormSelectInput;
}

export const FormFieldSelect: FC<FormSelectProps> = (props) => {
    const { field, onFieldChange, errorMessage } = props;

    // TODO: radio buttons (single select)

    const [selectValue, setSelectValue] = useState<string[]>([]); // array!

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setSelectValue((prevOptions) => {
            if (checked) {
                return [...prevOptions, name];
            } else {
                return prevOptions.filter((option) => option !== name);
            }
        });
    };

    useEffect(() => {
        onFieldChange(field.name, selectValue);
    }, [selectValue, field.name, onFieldChange]);

    return (
        <div>
            <div className={`${styles.xapp_select_title_container}${!field.title ? "__empty" : ""}`}>
                <p>{field.title}</p>
            </div>

            <div className={styles.xapp_select_container}>
                {field.items.map((item) => (
                    <FormControlLabel key={item.id}
                        control={
                            <Checkbox
                                checked={selectValue.includes(item.id)}
                                onChange={onChange}
                                name={item.id}
                                color="primary"
                                className={`${styles.xapp_checkbox_select}`}
                            />
                        }
                        label={item.label}
                    />
                ))}
            </div>

            {errorMessage && (
                <FormHelperText error style={{ marginLeft: "14px" }}>
                    {errorMessage}
                </FormHelperText>
            )}

        </div>
    );

};

export default FormFieldSelect;
