import React, { FC, useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { FormDropdownInput } from "stentor-models";

import { FormFieldProps } from "../SearchResponseFormDisplay";

export interface FormDropdownProps extends FormFieldProps {
    readonly field: FormDropdownInput;
}

export const FormFieldDropdown: FC<FormDropdownProps> = (props) => {
    const { field, onFieldChange } = props;

    const [dropValue, setDropValue] = useState("none");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (e: any) => {
        const { value } = e.target;
        setDropValue(value);
    };

    useEffect(() => {
        onFieldChange(field.name, dropValue);
    }, [dropValue, field.name, onFieldChange]);

    return (
        <div>
            <InputLabel shrink htmlFor={field.name}>
                {field.title}
            </InputLabel>
            <Select
                name={field.name}
                id={field.name}
                value={dropValue}
                onChange={onChange}
                size="small"
                displayEmpty>
                <MenuItem value="none">
                    <em>None</em>
                </MenuItem>
                {field.items.map((item) => {
                    return <MenuItem value={item.id}>{item.label}</MenuItem>;
                })}
            </Select>
        </div>
    );
};

export default FormFieldDropdown;
