
import { FormInput, FormTextInput, FormChipsInput } from "stentor-models";

export function isFormTextInput(field: FormInput): field is FormTextInput {
    return !!field && field.type === "TEXT";
}

export function isFormChipsInput(field: FormInput): field is FormChipsInput {
    return !!field && field.type === "CHIPS";
}

export function isFormDateInput(field: FormInput): field is FormChipsInput {
    return !!field && field.type === "DATE";
}

export function isFormCheckInput(field: FormInput): field is FormChipsInput {
    return !!field && field.type === "CHECK";
}