import React, { FC } from "react";
import { Display } from "stentor-models";
import { isCarousel, isForm, isList } from "../../utils/guards";
import { SearchResponseCarouselDisplay } from "../SearchResponseCarouselDisplay";
import { SearchResponseListDisplay } from "../SearchResponseListDisplay";
import { SearchResponseFormDisplay } from "../SearchResponseFormDisplay";

export interface SearchResponseDisplayProps {
    readonly display: Display;
    readonly loading: boolean;
    onActionResponse(data: unknown, action: string): void;
}

// TODO: Rename this to ResponseDisplay
export const SearchResponseDisplay: FC<SearchResponseDisplayProps> = props => {
    const { display, onActionResponse, loading } = props;

    return (
        <div className="xapp-search-response-display">
            {isForm(display) && <SearchResponseFormDisplay form={display} loading={loading} onActionResponse={onActionResponse} />}
            {isList(display) && <SearchResponseListDisplay list={display} />}
            {isCarousel(display) && <SearchResponseCarouselDisplay carousel={display} />}
        </div>
    );
};