import React, { VFC } from "react";

const strokeWidth = 16;
const strokeWidthHalf = strokeWidth / 2;
const strokeWidthQuarter = strokeWidth / 4;

export const SpinnerIcon:VFC = () => {
    return (
        <svg
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>
      
        <g strokeWidth={strokeWidth}>
          <path stroke="url(#spinner-secondHalf)" d={`M${strokeWidthHalf} 100 A${100 - strokeWidthHalf} ${100 - strokeWidthHalf} 0 0 1 ${200 - strokeWidthHalf} 100`} />
          <path stroke="url(#spinner-firstHalf)" d={`M${200 - strokeWidthHalf} 100 A${100 - strokeWidthHalf} ${100 - strokeWidthHalf} 0 0 1 ${strokeWidthHalf} 100`} />
      
          <path
            stroke="currentColor"
            strokeLinecap="round"
            d={`M${strokeWidthHalf} 100 A${100 - strokeWidthHalf} ${100 - strokeWidthHalf} 0 0 1 ${strokeWidthHalf} ${100 - strokeWidthQuarter}`}
          />
        </g>
      
        <animateTransform
          from="0 0 0"
          to="360 0 0"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1300ms"
        />
      </svg>
    );
};