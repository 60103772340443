import React, { FC } from "react";
import { Response, SuggestionTypes } from "stentor-models";
import { SearchResponseBubble } from "../SearchResponseBubble";
import { SearchResponseDisplay } from "../SearchResponseDisplay";
import { SearchResponseOutputSpeech } from "../SearchResponseOutputSpeech";
import { SearchResponsePart } from "../SearchResponsePart";
import { SuggestedActions } from "../SuggestedActions";
import styles from "./SearchResponse.module.scss";
import { isForm } from "../../utils/guards";

export interface SearchResponseProps {
    readonly response: Response;
    readonly className?: string;
    readonly loading: boolean;
    onActionClick(action: SuggestionTypes): void;
    onActionResponse(data: unknown, action: string): void;
    onAudioChange(isOn: boolean): void;
}

/**
 * SearchResponse displays the data from the handler
 * 
 * @param props 
 * @returns 
 */
export const SearchResponse: FC<SearchResponseProps> = (props) => {
    const { response, className, onActionClick, onActionResponse, onAudioChange, loading } = props;

    const { outputSpeech, displays } = response;
    const actions = typeof outputSpeech === "object" ? outputSpeech.suggestions : undefined;

    return (
        <div className={`${styles.xapp_search_response} ${className || ""}`}>
            {!!outputSpeech && (
                <SearchResponsePart>
                    <SearchResponseBubble>
                        <SearchResponseOutputSpeech
                            outputSpeech={outputSpeech}
                            onAudioChange={onAudioChange}
                        />
                    </SearchResponseBubble>
                </SearchResponsePart>
            )}

            {!!actions?.length && actions?.length > 0 && (
                <SuggestedActions actions={actions} onActionClick={onActionClick} />
            )}

            {!!displays?.length &&
                displays?.length > 0 &&
                displays.map((display, index) => {
                    const noHeaderForm: boolean = isForm(display) && (!display.header || display.header.length === 0);

                    return (
                        <SearchResponsePart key={index} extraClass={noHeaderForm ? "noHeaderForm" : ""}>
                            <SearchResponseDisplay
                                display={display}
                                onActionResponse={onActionResponse}
                                loading={loading}
                            />
                        </SearchResponsePart>
                    );
                })}
        </div>
    );
};

export interface FormResponseProps extends SearchResponseProps { };
export const FormResponse = SearchResponse
