import React, { FC, useEffect, useState } from "react";

import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import CheckIcon from '@mui/icons-material/Check';

import { FormChipsInput } from "stentor-models";

import { FormFieldProps } from "../SearchResponseFormDisplay";
import styles from './FormFieldChips.module.scss';

export interface FormFieldChipsProps extends FormFieldProps {
    readonly field: FormChipsInput;
}

export const FormFieldChips: FC<FormFieldChipsProps> = (props) => {
    const { field, onFieldChange, errorMessage } = props;

    /**
     * Selected chips
     */
    // initial value is looking through to see if any one is already selected
    const selectedFromProps = props.field.items.filter((item) => item.selected).map((item) => item.id);
    const [selectedChips, setSelectedChips] = useState<string[]>(selectedFromProps); // array!

    const handleChipClick = (id: string) => {
        const checked = selectedChips.includes(id);

        if (field.radio) {
            setSelectedChips((_prevOptions) => {
                if (!checked) {
                    return [id]; // add
                } else {
                    return []; // delete
                }
            });
        } else {
            setSelectedChips((prevOptions) => {
                if (!checked) {
                    return [...prevOptions, id]; // add
                } else {
                    return prevOptions.filter((option) => option !== id); // delete
                }
            });
        }

    };

    useEffect(() => {
        if (typeof onFieldChange === "function") {
            onFieldChange(field.name, selectedChips);
        }
    }, [selectedChips, field.name, onFieldChange]);

    return (
        <div>
            <div className={`${styles.xapp_chips_title_container}${!field.title ? "__empty" : ""}`}>
                <p>{field.title}</p>
            </div>

            <div className={styles.xapp_chips_container}>
                {field.items.map((item, index) => (
                    <div key={index} className={styles.xapp_chip_wrapper}>
                        <Chip
                            label={item.label}
                            clickable
                            color={selectedChips.includes(item.id) ? "primary" : "default"}
                            onClick={() => handleChipClick(item.id)}
                            icon={selectedChips.includes(item.id) ? <CheckIcon /> : undefined}
                            className={`${styles.xapp_checkbox_chip} ${selectedChips.includes(item.id) ? styles.xapp_chip_clicked : ""}`}
                        />
                    </div>
                ))}
            </div>

            {errorMessage && (
                <FormHelperText error style={{ marginLeft: "14px" }}>
                    {errorMessage}
                </FormHelperText>
            )}
        </div>
    );

};

export default FormFieldChips;
