import React, { FC } from "react";
import { ListItem } from "stentor-models";
import { reduceLink } from "./utils";
import styles from "./Card.module.scss";

export interface CardProps {
    readonly item: ListItem;
    readonly className?: string;
}

export const Card: FC<CardProps> = props => {
    const { item } = props;

    const imageUrl = item.image?.url;

    return (
        <div className={`${styles.xapp_search_card} ${props.className || ""}`}>
            {!!imageUrl &&
                <div className={styles['xapp_search_card__featured']}>
                    <div className={styles['xapp_search_card__featured_box']}>
                        <img
                            src={item.image?.url}
                            className={styles['xapp_search_card__img']}
                            alt={item.image?.accessibilityText}
                        />
                    </div>
                </div>
            }
            <div className={styles['xapp_search_card__content']}>
                {!!item.url && <a href={item.url} className={styles['xapp_search_card__title']}>
                    {item.title}
                </a>}
                {!!item.url && <div className={styles['xapp_search_card__link']}>{reduceLink(item.url, 40)}</div>}
                <div className={styles['xapp_search_card__description']}>
                    {item.description}
                </div>
            </div>
        </div>
    );
};