export function parseAudio(ssml: string): string[] {
    const regex = /<audio\s+src\s*=\s*"(.+?)"\s*\/?>/g;
    const matches: string[] = [];
    let match: string[] | null;

    while ((match = regex.exec(ssml)) !== null) {
        matches.push(match[1]);
    }

    return matches;
}
