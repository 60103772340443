import React, { FC } from "react";

export interface SearchResponseTextProps {
    readonly text: string;
}

export const SearchResponseText: FC<SearchResponseTextProps> = props => {
    const { text } = props;
    return (
        <div className="xapp-search-response-text">
            <p>
                {text}
            </p>
        </div>
    );
};