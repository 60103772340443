import React, { FC, useCallback } from "react";
import { isLinkoutSuggestion } from "stentor-guards/lib/isLinkoutSuggestion";
import { SuggestionTypes } from "stentor-models";
import { getActionLabel } from "../../utils/getActionLabel";
import { Button } from "../Button";
import styles from "./SuggestedAction.module.scss";

export interface SuggestedActionProps {
    readonly action: SuggestionTypes;
    onClick(action: SuggestionTypes): void;
    readonly className?: string;
}

export const SuggestedAction: FC<SuggestedActionProps> = props => {
    const { action, className, onClick } = props;

    const handleClick = useCallback(() => {
        onClick(action);
    }, [action, onClick])

    if (isLinkoutSuggestion(action)) {
        return (
            <span className={`${styles.xapp_search_suggested_action} ${className || ""}`}>
                <a href={action.url}>{getActionLabel(action)}</a>
            </span>
        );
    } else {
        return (
            <Button
                className={`${styles.xapp_search_suggested_action} ${className || ""}`}
                onClick={handleClick}
            > {getActionLabel(action)}</Button >
        );
    }
}