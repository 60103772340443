import React, { FC, PropsWithChildren } from "react";
import styles from "./CarouselItem.module.scss";

export interface CarouselItemProps {
    readonly className?: string;
}

export const CarouselItem: FC<PropsWithChildren<CarouselItemProps>> = props => {
    return (
        <div className={`${styles.xapp_search_carousel_item} ${props.className || ""}`}>
            {props.children}
        </div>
    );
};

