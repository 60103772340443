import React, { FC, useContext } from "react";
import { ResponseOutput } from "stentor-models";
import { SearchResponseHtml } from "../SearchResponseHtml";
import { SearchResponseText } from "../SearchResponseText";
import { SearchResponseAudio } from "../SearchResponseAudio";
import styles from "./SearchResponseOutputSpeech.module.scss";
import { parseAudio } from "../../utils/parseAudio";
import { SearchWidgetConfigContext } from "../../utils/SearchWidgetConfigContext";

export interface SearchResponseOutputSpeechProps {
    readonly outputSpeech: ResponseOutput | string;
    onAudioChange(isOn: boolean): void;
}

export const SearchResponseOutputSpeech: FC<SearchResponseOutputSpeechProps> = props => {
    const { outputSpeech, onAudioChange } = props;
    
    const text = typeof outputSpeech === "string" ? outputSpeech : outputSpeech?.displayText;
    const html = typeof outputSpeech === "string" ? undefined : outputSpeech?.html;
    const ssml = typeof outputSpeech === "string" ? undefined : outputSpeech?.ssml;

    const config = useContext(SearchWidgetConfigContext);

    // play the firts audio if present
    let audioUrl: string | undefined;

    if (!!config.isVoiceEnabled) {
        const mp3s = ssml ? parseAudio(ssml) : [];
        audioUrl = mp3s.length > 0 ? mp3s[0] : undefined;
    }

    return (
        <div
            className={styles.xapp_search_response_output_speech}>
            {!!html && <SearchResponseHtml html={html} />}
            {!!text && !html && <SearchResponseText text={text} />}
            {!!audioUrl && <SearchResponseAudio audio={audioUrl} onAudioChange={onAudioChange} />}
        </div>
    );
}
