import { FormWidgetEnv, FormWidgetTheme } from "@xapp/stentor-form-widget-channel";

import { FormWidgetConfig } from "../config";

/**
 * Parses the widget environment to extract the configuration and theme.
 * 
 * @param env 
 * @returns An array containing the configuration and theme, config in index 0 and theme in index 1.
 */
export function parseWidgetEnv(env: FormWidgetEnv): [FormWidgetConfig, FormWidgetTheme] {
    const config: FormWidgetConfig = {
        api: {
            url: env.connection?.serverUrl || "",
            key: env.connection?.accountKey || ""
        },

        suggestionsUrl: env.autocompleteSuggestionsUrl,
        autoGreeting: env.autoGreeting || "contact us",
        intentId: env.intentId,
        sideButtonLabel: env.sideButtonLabel,
        googleMapsApiKey: env.googleMapsApiKey,
        mapsBaseUrl: env.mapsBaseUrl,
        autoOpen: env.autoOpen,
        disabled: env.disabled
    }

    const theme = env.theme;

    return [config, theme];
}