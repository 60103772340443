import { throwBadKind } from "../../utils/guards";
import { SearchAction } from "../actions";
import { SearchState } from "../SearchState";

export function RootReducer(state: SearchState, action: SearchAction): SearchState {
    const { type } = action;
    switch (type) {
        case "set-query":
            return {
                ...state,
                query: action.query || ""
            };
        case "set-response":
            return {
                ...state,
                responseQuery: action.query,
                responseIntentId: action.intentId
            };
        case "set-context":
            return {
                ...state,
                context: action.context
            };
        case "new-search":
            return {
                ...state,
                query: "",
            };
        case "set-modal-state":
            return {
                ...state,
                active: action.active,
                minimized: action.minimized,
                sendRequest: action.sendRequest
            };
        case "toggle-modal-state":
            const { active, minimized } = state;

            if (active || minimized) {
                return {
                    ...state,
                    active: false,
                    minimized: false,
                    sendRequest: false
                };
            } else {
                return {
                    ...state,
                    active: true,
                    minimized: false,
                    sendRequest: true
                };
            }
        default:
            throwBadKind(type);
    }
}