import { useCallback, useMemo, useState } from "react";
import { useIsMounted } from "./useIsMounted";

export type LoadingAction<TResult> = () => Promise<TResult>;

export type LoadingRunAction<TResult> = (action: LoadingAction<TResult>) => Promise<TResult>;

export interface LoadingState {
    readonly loading: boolean;
    run<TResult>(action: LoadingAction<TResult>): Promise<TResult>;
}

function runCounter<TResult>(action: LoadingAction<TResult>, shift: (delta: number) => void): Promise<TResult> {
    shift(1);
    return action().finally(() => {
        shift(-1);
    });
}

export function useLoading(): LoadingState {

    const [state, setState] = useState<number>(0);

    const mounted = useIsMounted();

    const run = useCallback(<TResult>(action: LoadingAction<TResult>) => {
        return runCounter(action, delta => {
            if (mounted()) {
                setState(prev => prev + delta);
            }
        });
    }, [mounted]);

    return useMemo(() => ({
        loading: !!state,
        run
    }), [state, run]);

}