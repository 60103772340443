import { uuid } from "@xapp/chat-widget-core";
import {
    ChannelActionRequest,
    IntentRequest,
    LaunchRequest,
    OptionSelectRequest,
    PermissionRequest,
    Response
} from "stentor-models";
import { StentorApiConfig } from "../config";
import { err, log } from "../utils";

export type StentorRequest = IntentRequest | LaunchRequest | OptionSelectRequest | PermissionRequest | ChannelActionRequest;

const delay = (waitTimeInMs: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, waitTimeInMs);
    });

const XAPP_USER_ID = "__xapp_form_user_id";

let API: StentorApi;

const RESPONSE_TTL_MS = 900 * 1000; // 15 minutes

export class StentorApi {
    private responseCache: {
        [key: string]: {
            ts: number;
            value: Response;
        }
    }

    private readonly config: StentorApiConfig;
    private session: StentorApiSession | undefined;

    private prefetchSemaphore = Promise.resolve();

    private constructor(config: StentorApiConfig) {
        this.config = config;
        this.responseCache = {};
    }

    public static getApi(config: StentorApiConfig): StentorApi {
        if (API) {
            return API;
        }

        API = new StentorApi(config);

        if (config.prefetchQuery) {
            API.prefetch(config.prefetchQuery);
        }

        return API;
    }

    public launchRequest(query: string): Promise<Response> {
        return this.send({
            type: "LAUNCH_REQUEST",
            rawQuery: query,
            intentId: "LaunchRequest",
            platform: "stentor-platform",
            channel: "form-widget"
        } as StentorRequest);
    }

    public intentRequest(request: { query?: string, intentId?: string }): Promise<Response> {

        // if we have the intentId, use that and set rawQuery to be undefined
        // so that stentor doesn't try to resolve it again
        // otherwise, use the query and set intentId to be "NLU_RESULT_PLACEHOLDER"
        const rawQuery: string | undefined = request?.intentId ? undefined : request.query;
        const intentId: string = request?.intentId ? request.intentId : "NLU_RESULT_PLACEHOLDER";

        return this.send({
            type: "INTENT_REQUEST",
            rawQuery,
            intentId,
            platform: "stentor-platform",
            channel: "form-widget"
        } as StentorRequest);
    }

    public channelActionRequest(data: unknown, action: string): Promise<Response> {
        if (this.session) {
            if (this.session.hadFinal) {
                log(`Had final submit. Not sending action request: ${action}`);
                return Promise.resolve({});
            }

            this.session.hadFinal = !!(data as any).final;
        }

        return this.send({
            type: "CHANNEL_ACTION_REQUEST",
            action,
            platform: "stentor-platform",
            channel: "form-widget",
            attributes: {
                data
            }
        } as unknown as StentorRequest);
    }

    public resetSession(): void {
        this.session = undefined;
    }


    private getCachedResponse(query: string | undefined): Response | undefined {
        if (!query) {
            return;
        }

        const now = new Date().getTime();
        const hit = this.responseCache[query];

        if (hit) {
            if ((now - hit.ts) < RESPONSE_TTL_MS) {
                log(`Returning cached response for "${query}"`);
                return hit.value;
            }

            log(`Deleting expired cached response for "${query}"`);
            delete this.responseCache[query];
        }

        return;
    }

    private async send(data: Omit<StentorRequest, "sessionId" | "userId" | "isNewSession">): Promise<Response> {
        await this.prefetchSemaphore;

        const hit = this.getCachedResponse(data.rawQuery);
        if (hit) {
            return Promise.resolve(hit);
        }

        const { url, key } = this.config;

        const newSession = !this.session;

        if (!this.session) {
            let storedUserId = localStorage.getItem(XAPP_USER_ID);

            if (!storedUserId) {
                storedUserId = uuid();
                localStorage.setItem(XAPP_USER_ID, storedUserId);
            }

            this.session = {
                sessionId: `stentor-form-session-${uuid()}`,
                userId: storedUserId,
                hadFinal: false
            };
        }

        const attributes: Record<string, unknown> = {};
        // look for certain attributes on the browser and add them.
        // get xa_rwg_token from local storage
        const rwgToken = localStorage.getItem("xa_rwg_token");
        if (rwgToken) {
            attributes["rwg_token"] = rwgToken;
        }
        const merchantId = localStorage.getItem("xa_merchant_id");
        if (merchantId) {
            attributes["merchant_id"] = merchantId;
        }
        const environment = localStorage.getItem("xa_environment");
        if (environment) {
            attributes["environment"] = environment;
        }
        const enablePreferredTime = localStorage.getItem("xa_enablePreferredTime");
        if (enablePreferredTime) {
            attributes["enablePreferredTime"] = enablePreferredTime;
        }
        const service = localStorage.getItem("xa_service");
        if (service) {
            attributes["service"] = service;
        }
        const origin = localStorage.getItem("xa_origin");
        if (origin) {
            attributes["origin"] = origin;
        }

        const { sessionId, userId } = this.session;
        const request = {
            ...data,
            sessionId,
            userId,
            isNewSession: newSession,
            attributes: {
                ...data.attributes,
                ...attributes
            }
        } as StentorRequest;
        const body = JSON.stringify(request);

        const params: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${key}`,
                "User-Agent": "Stentor Chat Server"
            },
            body,
            mode: "cors"
        };

        let response = await fetch(url, params);

        if (!response.ok) {
            err(`Stentor call failed: ${response.statusText}`);

            await delay(1000);
            response = await fetch(url, params); // once more
            if (!response.ok) {
                err(`Stentor call failed again: ${response.statusText}`);
                throw new Error(`Status ${response.status}, Text: ${response.statusText}`);
            }
        }

        return response.json();
    }

    private async prefetch(query: string): Promise<void> {
        const hit = this.getCachedResponse(query);
        if (hit) {
            return;
        }

        let resolver: (value: void | PromiseLike<void>) => void;

        this.prefetchSemaphore = new Promise(resolve => {
            resolver = resolve;
        });

        const { url, key } = this.config;

        const newSession = !this.session;

        if (!this.session) {
            let storedUserId = localStorage.getItem(XAPP_USER_ID);

            if (!storedUserId) {
                storedUserId = uuid();
                localStorage.setItem(XAPP_USER_ID, storedUserId);
            }

            this.session = {
                sessionId: `stentor-form-session-${uuid()}`,
                userId: storedUserId,
                hadFinal: false
            };
        }

        const { sessionId, userId } = this.session;

        const data = {
            type: "INTENT_REQUEST",
            rawQuery: query,
            intentId: "NLU_RESULT_PLACEHOLDER",
            platform: "stentor-platform",
            channel: "form-widget"
        };

        const request = {
            ...data,
            sessionId,
            userId,
            isNewSession: newSession
        } as StentorRequest;

        const body = JSON.stringify(request);

        const params: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${key}`,
                "User-Agent": "Stentor Chat Server"
            },
            body,
            mode: "cors"
        };

        const start = new Date().getTime();

        fetch(url, params).then(async fetchResponse => {
            if (!fetchResponse.ok) {
                err(`Stentor prefetch failed: ${fetchResponse.statusText}`);
                return;
            }

            const now = new Date().getTime();

            this.responseCache[query] = {
                ts: now,
                value: await fetchResponse.json()
            }

            log(`Stentor prefetch took: ${now - start} ms`);
        }).finally(() => {
            resolver();
        });

        return;
    }
}

export interface StentorApiSession {
    readonly sessionId: string;
    readonly userId: string;
    hadFinal: boolean;
}
