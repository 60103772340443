import React from "react";
import { FormWidgetConfig } from "../config";

const defaultValue: FormWidgetConfig = {
    api: {
        key: "",
        url: ""
    },
    suggestionsUrl: "",
    isVoiceEnabled: false,
    autoGreeting: "schedule" // default utterance to trigger the form if not set in config
};

export const SearchWidgetConfigContext = React.createContext<FormWidgetConfig>(defaultValue);