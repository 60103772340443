type BooleanMap = { [x: string]: boolean };

export const shallowEqualBooleanMap = (objA: BooleanMap, objB: BooleanMap): boolean => {
    // Get keys
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if number of keys is different
    if (keysA.length !== keysB.length) return false;

    // Check if keys or values are different
    for (const key of keysA) {
        if (objA[key] !== objB[key]) return false;
    }

    // If passed all checks, objects are considered shallowly equal
    return true;
};

/**
 *
 * @param input Prefix javascript variables in simple logical expression with ".this"
 * @returns
 */
export const addContextPrefix = (input: string, prefix = "this"): string => {
    // Regular expression to match variable names: words that are not JavaScript keywords
    const variablePattern =
        /\b(?<!')(?!(?:if|else|length|for|while|includes|function|var|let|const|return|switch|case|do|true|false|null|undefined|typeof|new|this|void|delete|in|instanceof|export|import)\b)([a-zA-Z_$][a-zA-Z0-9_$]*)\b/g;

    // Replace each match with "this." prefixed
    const output = input.replace(variablePattern, `${prefix}.$1`);

    return output;
}

// console.log(addContextPrefix("name === 'John' && age > 30"));
