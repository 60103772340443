import classnames from "classnames";
import React, { CSSProperties, FC } from "react";
import "./style.css";

export interface LoadingSpinnerProps {
    /**
     * Text that is added to the loading spinner.
     *
     * It is placed below the spinner.
     */
    loadingText?: string;
    /**
     * Styles applied directly to the spinner
     */
    loaderStyle?: CSSProperties;
    /**
     * Styles applied to the optional loading text.
     */
    textStyle?: CSSProperties;
}

/**
 * Animated loading spinner of the XAPP "squares" logo. Add `loadingText=` for a custom message.
 * Note: When we make @xapp/components public, replace this with the one from that library 
 */
export const LoadingSpinner: FC<LoadingSpinnerProps> = props => {
    return (
        <div className={"Loader"} style={props.loaderStyle}>
            <div className={"Carriage"}>
                <div className={"Box"} />
                <div className={classnames("Box", "second")} />
                <div className={classnames("Box", "spacer")} />
                <div className={classnames("Box", "spacer")} />
                <div className={classnames("Box", "third")} />
                <div className={classnames("Box", "fourth", "white")} />
            </div>
            <h2 style={props.textStyle}>{props.loadingText}</h2>
        </div>
    );
};
