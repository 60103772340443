import { MessageBubble } from "@xapp/chat-widget-core";
import React, { FC, PropsWithChildren } from "react";
import styles from "./SearchResponseBubble.module.scss";

export interface SearchResponseBubbleProps { }

export const SearchResponseBubble: FC<PropsWithChildren<SearchResponseBubbleProps>> = props => {
    return (
        <div className={styles.xapp_search_response_bubble}>
            <MessageBubble>
                {props.children}
            </MessageBubble>
        </div>
    );
};