import { IconButton } from "@xapp/chat-widget-core";
import React, { FC, useCallback, useRef } from "react";
import { List } from "stentor-models";
import { Card } from "../Card";
import { LeftAngleIcon, RightAngleIcon } from "../icons";
import styles from "./Carousel.module.scss";
import { CarouselItem } from "./CarouselItem";

export interface CarouselProps {
    readonly list: List;
}

export const Carousel: FC<CarouselProps> = props => {
    const { list } = props;

    const listRef = useRef<HTMLDivElement>(null);

    const scrollIterate = useCallback((func: (item: { position: number, size: number }) => number | undefined) => {
        const container = listRef.current;

        if (container) {
            const items = container.getElementsByClassName("xapp_search_carousel-item");

            const containerRect = container.getBoundingClientRect();

            const length = items.length;

            for (let index = 0; index < length; index++) {
                const item = items.item(index);
                if (item) {
                    const itemRect = item.getBoundingClientRect();
                    const pos = itemRect.left - containerRect.left;
                    const res = func({ position: pos, size: itemRect.width });
                    if (res !== undefined) {
                        container.scrollLeft += res;
                        return;
                    }
                }
            }
        }
    }, []);

    const handlePrev = useCallback(() => {
        let prevPos: undefined | number;
        scrollIterate(item => {
            const threshold = 10;
            if (item.position > -threshold) {
                return prevPos;
            }
            prevPos = item.position;
            return undefined;
        });
    }, [scrollIterate]);

    const handleNext = useCallback(() => {
        scrollIterate(item => {
            const threshold = 10;
            if (item.position > threshold) {
                return item.position;
            }
            return undefined;
        });
    }, [scrollIterate]);

    return (
        <div className={styles.xapp_search_carousel}>
            <IconButton icon={LeftAngleIcon} className={styles['xapp_search_carousel__prev']} onClick={handlePrev} />
            <div ref={listRef} className={styles['xapp_search_carousel__items']}>
                {list.items.map((item, index) => (
                    <CarouselItem key={index} >
                        <Card item={item} className={styles['xapp_search_carousel__content']} />
                    </CarouselItem>
                ))}
            </div>
            <IconButton icon={RightAngleIcon} className={styles['xapp_search_carousel__next']} onClick={handleNext} />
        </div>
    );
};
