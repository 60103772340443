import React, { FC, useEffect, useState } from 'react';

import {
    FormWidget,
    FormWidgetConfig,
    WidgetStylesheet
} from "@xapp/form-widget";


export interface ContactPageProps {

    /**
     * Name of the business, displayed in the footer
     */
    businessName?: string;
    /**
     * URL of the business logo, displayed in the header
     */
    businessLogoUrl?: string;
    /**
     * Address of the business, displayed in the header
     * 
     */
    businessAddress?: string;
    /**
     * Website of the business, displayed in the footer
     */
    businessWebsite?: string;

    config: FormWidgetConfig;

    theme?: {
        backgroundColor?: string;
        headerBackgroundColor?: string;
        headerBusinessNameColor?: string;
    }

    /**
     * Disables the "Powered by XAPP AI" link in the footer
     */
    disablePoweredBy?: boolean;
}

/**
 * Stand alone contact page that uses the FormWidget
 * 
 * You must also include WidgetStylesheet on the same view/page as the ContactPage to successfully style the widget.
 * @param props 
 * @returns 
 */
export const ContactPage: FC<ContactPageProps> = (props) => {

    const {
        config,
        businessAddress,
        businessName,
        businessWebsite,
        businessLogoUrl,
        disablePoweredBy,
        theme
    } = props;

    const { backgroundColor, headerBackgroundColor, headerBusinessNameColor } = theme || {};

    return (
        <div className="App" style={{ backgroundColor }}>
            {(businessName || businessLogoUrl) ? (
                <div className="Header" style={{ backgroundColor: headerBackgroundColor }}>
                    {businessLogoUrl && <img className={"BusinessLogo"} src={businessLogoUrl} alt={businessName} />}
                    {businessAddress && <p className={"BusinessAddress"} style={{ color: headerBusinessNameColor }}>{businessAddress}</p>}
                </div>
            ) : null}
            <div className="Content">

                <FormWidget config={config} docked={true} />
            </div>
            <div className="Footer">
                <div className="BusinessInfo">
                    {businessName && <b>{businessName}</b>}
                    {businessAddress && <p>{businessAddress}</p>}
                    {businessWebsite && <a href={businessWebsite}>Visit Our Website</a>}
                </div>
                {!disablePoweredBy && <a className={"XAPP"} target="_blank" href="https://xapp.ai">Powered by XAPP AI</a>}
            </div>
        </div>
    );
};