import React, { FC } from "react";

export interface SearchResponseHtmlProps {
    readonly html: string;
}

export const SearchResponseHtml: FC<SearchResponseHtmlProps> = props => {
    const { html } = props;
    return (
        <div
            className="xapp-search-response-html"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};