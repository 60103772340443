import React, { FC, useCallback, useContext, useEffect, useMemo, useState, VFC } from "react";
import { Response, SuggestionTypes } from "stentor-models";
import { StentorApi } from "../../api";
import { FormWidgetConfig } from "../../config";
import { useSearchStore } from "../../store";
import { getActionLabel } from "../../utils/getActionLabel";
import { log } from "../../utils";
import { SearchWidgetConfigContext } from "../../utils/SearchWidgetConfigContext";
import { useLoading } from "../../utils/useLoading";
import { SpinnerIcon } from "../icons";
import { SearchResponse } from "../SearchResponse";

import styles from "./FormWidget.module.scss";
import { FormResponse } from "../SearchResponse/SearchResponse";

export const AUDIO_WAIT_NOTHING = 0, AUDIO_WAIT_CLOSE = 1, AUDIO_WAIT_MINIMIZE = 2;

export interface FormWidgetProps {

    readonly config: FormWidgetConfig;
    /**
     * Optional initial response to display
     */
    readonly response?: Response;

    readonly docked?: boolean;
}

export const FormWidget: FC<FormWidgetProps> = props => {

    const { config } = props;

    return (
        <SearchWidgetConfigContext.Provider value={config}>
            <FormWidgetRaw docked={props.docked} />
        </SearchWidgetConfigContext.Provider>
    );
}

const FormWidgetRaw: VFC<Pick<FormWidgetProps, "docked" | "response">> = (props) => {

    const { docked } = props;

    const [state, dispatch] = useSearchStore();
    const { loading, run } = useLoading();

    const { query, sendRequest, active } = state;

    const [response, setResponse] = useState<Response | undefined>(props.response);

    const [audioOn, setAudioOn] = useState<boolean>(false);

    const [waitingOnAudioToFinish, setwaitingOnAudioToFinish] = useState<number>(AUDIO_WAIT_NOTHING);

    // why don't we just pass it in as a prop?
    const config = useContext(SearchWidgetConfigContext);

    const api = useMemo(() => {
        return StentorApi.getApi({
            key: config.api.key,
            url: config.api.url,
            // prefetchQuery: config.autoGreeting
        });
    }, [config]);

    const handleCancel = useCallback(() => {
        setResponse(undefined);
        dispatch({
            type: "new-search"
        });
        dispatch({
            type: "set-modal-state",
            active: false,
            minimized: true,
            sendRequest: false
        });
    }, [dispatch]);

    const handleClose = useCallback(() => {
        setResponse(undefined);
        dispatch({
            type: "new-search"
        });
        dispatch({
            type: "set-modal-state",
            active: false,
            minimized: false,
            sendRequest: false

        });
        api.channelActionRequest({ data: { result: {}, step: "", form: "" } }, "FORM_CLOSE").then(response => {
            log(`ACTION Request (FORM_CLOSE). Data: Response: ${JSON.stringify(response)}`);
            setwaitingOnAudioToFinish(AUDIO_WAIT_NOTHING);
        });
    }, [api, dispatch]);

    const intentRequest = useCallback((request: { query?: string, intentId?: string }) => {
        dispatch({
            query: request.query,
            intentId: request.intentId,
            type: "set-query"
        });

        run(() => api.intentRequest(request).then(response => {
            setResponse(response);
            dispatch({
                type: "set-response",
                response,
                query: request.query,
                intentId: request.intentId
            });
        }));
    }, [api, dispatch, run]);

    const channelActionRequest = useCallback((data: any, action: string) => {
        run(() => api.channelActionRequest(data, action).then(response => {
            log(`ACTION Request (${action}). Data: ${JSON.stringify(data)} Response: ${JSON.stringify(response)}`);

            if (response.outputSpeech || response.displays) {
                dispatch({
                    type: "set-response",
                    response,
                    query: ""
                });
            }

            // Server side context (usually session params)
            if (response.context?.active && response.context.active.length > 0) {
                const context = response.context.active[0].parameters || {};

                dispatch({
                    type: "set-context",
                    context
                });
            }

            if (!!data.autoClose) {
                setwaitingOnAudioToFinish(AUDIO_WAIT_CLOSE);
            } else if (!!data.autoMinimize) {
                setwaitingOnAudioToFinish(AUDIO_WAIT_MINIMIZE);
            } else {
                setwaitingOnAudioToFinish(AUDIO_WAIT_NOTHING);
            }
        }));
    }, [api, dispatch, run]);

    useEffect(() => {
        // if we need to send, it is active and we have an autoGreeting or intentId
        if (docked || (sendRequest && active && (config.autoGreeting || config.intentId))) {
            api.resetSession();
            intentRequest({ query: config.autoGreeting, intentId: config.intentId });
            dispatch({
                type: "set-modal-state",
                active: true,
                minimized: false,
                sendRequest: false
            });
        }
    }, [docked, sendRequest, active, intentRequest, dispatch, config.autoGreeting, api, config.intentId]);

    useEffect(() => {
        if (!audioOn) {
            if (waitingOnAudioToFinish === AUDIO_WAIT_CLOSE) {
                setResponse(undefined);
                dispatch({
                    type: "new-search"
                });
                dispatch({
                    type: "set-modal-state",
                    active: false,
                    minimized: false,
                    sendRequest: false
                });
                setwaitingOnAudioToFinish(AUDIO_WAIT_NOTHING);
            } else if (waitingOnAudioToFinish === AUDIO_WAIT_MINIMIZE) {
                setResponse(undefined);
                dispatch({
                    type: "new-search"
                });
                dispatch({
                    type: "set-modal-state",
                    active: false,
                    minimized: true,
                    sendRequest: false
                });
                setwaitingOnAudioToFinish(AUDIO_WAIT_NOTHING);
            }
        }
    }, [audioOn, dispatch, waitingOnAudioToFinish]);

    const handleActionClick = useCallback((item: SuggestionTypes) => {
        const text = getActionLabel(item);
        intentRequest({ query: text });
    }, [intentRequest]);

    const handleActionResponse = useCallback((data: unknown, action: string) => {
        if (!action) {
            action = (data as any).action;
        }
        // Tell the server the visitors TZ offset
        (data as any).timezoneOffset = new Date().getTimezoneOffset();
        channelActionRequest(data, action);
    }, [channelActionRequest]);

    const handleAudioChange = (isOn: boolean) => {
        setAudioOn(isOn);
    };

    const displayContainer: boolean = !!query || !!response;

    if (docked) {
        // we might want to put a wrapper around this for docked mode 
        return (
            <div className={`${styles.xapp_form_widget} ${loading ? styles.loading : ''}`} >
                <div className={`${styles['xapp_form_widget__content']} ${loading ? styles.loading : ''}`} >
                    {response ? (
                        <FormResponse
                            response={response}
                            className={styles['xapp_form_widget__response']}
                            onActionClick={handleActionClick}
                            onActionResponse={handleActionResponse}
                            onAudioChange={handleAudioChange}
                            loading={loading}
                        />
                    ) : (
                        <div className={styles['xapp_form_widget__spinner']}>
                            <SpinnerIcon />
                        </div>
                    )}
                </div>
            </div >
        );
    }

    return (
        <div className={`${styles.xapp_form_widget} ${loading && !response ? styles.loading : ""}`}>
            {displayContainer && (
                <div className={`${styles["xapp_form_widget__content"]} ${loading && !response ? styles.loading : ""}`}>

                    {!response && loading && (
                        <div className={styles["xapp_form_widget__spinner"]}>
                            <SpinnerIcon />
                        </div>
                    )}

                    {response && (
                        <SearchResponse
                            response={response}
                            className={styles["xapp_form_widget__response"]}
                            onActionClick={handleActionClick}
                            onActionResponse={handleActionResponse}
                            onAudioChange={handleAudioChange}
                            loading={loading}
                        />
                    )}

                </div>
            )}

            {response && !!handleCancel && (
                <div className={styles["xapp_form_widget__close"]} onClick={handleClose}>
                    {"\u2715"}
                </div>
            )}
        </div>
    );
};
