/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { Typography } from "@mui/material";

import { FormCardInput } from "stentor-models";

import { FormFieldProps } from "../SearchResponseFormDisplay";

import styles from "./FormFieldCard.module.scss";

export interface FormCardProps extends FormFieldProps {
    readonly field: FormCardInput;
}

const cardStyle: React.CSSProperties = {
    backgroundColor: "transparent", // "lightblue",
    boxShadow: "none", // "0px 4px 8px rgba(0, 0, 0, 0.2)",

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
};

const cardContentStyle: React.CSSProperties = {
    padding: "4px"
};

const typoStyle: React.CSSProperties = {
    whiteSpace: "pre-line"
};

export const FormFieldCard: FC<FormCardProps> = (props) => {
    const { field } = props;

    // onFieldChange(field.name, undefined);

    return (
        <div className={styles.xapp_form_card}>
            <Card style={cardStyle}>
                {field.header && (
                    <CardHeader title={field.header.title} subheader={field.header.subheader} />
                )}

                {field.media && (
                    <CardMedia
                        component="img"
                        alt={field.media.alt}
                        height={field.media.height}
                        width={field.media.width}
                        image={field.media.imageUrl}
                    />
                )}

                {field.text && (
                    <CardContent style={cardContentStyle}>
                        <Typography
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            variant={field.variant as any || "body2"}
                            color={field.color || "textSecondary"}
                            align={field.align as any || "center"}
                            style={{ ...typoStyle, ...field.style }}
                        >
                            {field.text}
                        </Typography>
                        {/* <span>
                            {field.text}
                        </span> */}
                    </CardContent>
                )}
            </Card>
        </div>
    );
};

export default FormFieldCard;
