import React, { FC } from "react";

import styles from "./SideButton.module.scss";

export interface SideButtonProps {
    readonly onClick?: () => void;
    readonly position?: string;
    readonly label?: string;
}

export const SideButton: FC<SideButtonProps> = (props) => {
    const { onClick, position, label } = props;

    const sideButtonPositionClass = position?.toLowerCase() === "right" ? styles.right : styles.left;

    return (
        <div
            onClick={onClick}
            className={`${styles.xapp_form_sticky_button} ${sideButtonPositionClass}`}>
                
            {/* <svg
                className={styles.xapp_form_sticky_button_left_img}
                width="69"
                height="43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1">
                <path
                    d="M0 0v43h68.4a38 38 0 0 1-32.71-18.66l-3.36-5.68A38 38 0 0 0 0 0Z"
                    fill="#EF7E45"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                />
            </svg> */}

            <span>{label || "Schedule Now"}</span>

            {/* <svg
                className={styles.xapp_form_sticky_button_right_img}
                width="69"
                height="43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                transform="matrix(1,0,0,-1,0,0)">
                <path
                    d="M0 0v43h68.4a38 38 0 0 1-32.71-18.66l-3.36-5.68A38 38 0 0 0 0 0Z"
                    fill="#EF7E45"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                />
            </svg> */}
        </div>
    );
};

export default SideButton;
