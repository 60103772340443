
export interface FieldValidatorResult {
    valid: boolean;
    message?: string;
}

export const validateEmail = (s: string): FieldValidatorResult => {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const valid = re.test(String(s).toLowerCase());
    const message = !valid ? "Invalid email address" : undefined;
    
    return { valid, message };
};

export const validatePhoneNumber = (s: string): FieldValidatorResult => {
    // Nothing is valid
    if (isEmptyString(s)) {
        return { valid: true };
    }

    const valid = validateUSPhoneNumber(s);
    const message = !valid ? "Invalid phone number" : undefined;

    return { valid, message };
};

export const isEmptyString = (s: string): boolean => {
    return typeof s !== 'string' || s.trim().length === 0;
}

const validateUSPhoneNumber = (s: string) => {
    const sanitizedNumber = s.replace(/\D/g, '');
  
    if (sanitizedNumber.length === 10 || (sanitizedNumber.length === 11 && sanitizedNumber.startsWith('1'))) {
      const validPattern = /^(1)?[-. ]?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
      return validPattern.test(sanitizedNumber);
    }
  
    return false;
}

export const formatUSPhoneNumber = (s: string) => {
    if (isEmptyString(s) || !validateUSPhoneNumber(s)) {
        return s;
    }

    let sanitizedNumber = s.replace(/\D/g, '');

    if (sanitizedNumber.length === 11 && sanitizedNumber.startsWith('1')) {
        sanitizedNumber = sanitizedNumber.slice(1);
    }
  
    const formattedNumber = sanitizedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  
    return formattedNumber;
}

export const fieldErrorsEqual = (obj1: { [key: string]: FieldValidatorResult }, obj2: { [key: string]: FieldValidatorResult }): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
            return false;
        }

        const attr1 = obj1[key];
        const attr2 = obj2[key];

        if (attr1.valid !== attr2.valid) {
            return false;
        }
    }

    return true;
}

export const isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0;
}
