import React, { FC, useEffect, PropsWithChildren } from "react";
import styles from "./ModalDialog.module.scss";

export interface ModalDialogProps {
    /**
     * Removes the backdrop overlay
     * 
     * Setting this to true will then disable the click backdrop to close functionality
     */
    readonly disableBackdrop?: boolean;
    /**
     * Callback when the user requests to close the modal by clicking the backdrop.
     */
    onClose(): void;
}

export const ModalDialog: FC<PropsWithChildren<ModalDialogProps>> = props => {

    useEffect(() => {
        document.body.classList.add(styles.xapp_search_opened);

        return () => {
            document.body.classList.remove(styles.xapp_search_opened);
        };
    }, []);

    return (
        <div className={styles.xapp_search_modal}>
            {!props.disableBackdrop && <div className={styles['xapp_search_modal__backdrop']} onClick={props.onClose} />}
            <div className={styles['xapp_search_modal__body']}>
                {props.children}
            </div>
        </div>
    );
}