import React from "react";
import { SearchDispatch } from "./SearchDispatch";
import { SearchState } from "./SearchState";
import { SearchStore } from "./SearchStore";

export const DEFAULT_SEARCH_STATE: SearchState = {
    query: "",
    active: false,
    minimized: false,
    sendRequest: true
};

const DEFAULT_SEARCH_DISPATCH: SearchDispatch = () => { };

const DEFAULT_SEARCH_STORE: SearchStore = [DEFAULT_SEARCH_STATE, DEFAULT_SEARCH_DISPATCH];

export const SearchStoreContext = React.createContext<SearchStore>(DEFAULT_SEARCH_STORE);