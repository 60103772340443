import React, { FC, useEffect, useState } from "react";

import { FormTextInput } from "stentor-models";

import { FormFieldProps } from "../SearchResponseFormDisplay";

import TextField from "@mui/material/TextField";

import styles from "./FormFieldTextInput.module.scss";

export interface FormTextInputProps extends FormFieldProps {
    readonly field: FormTextInput;
    readonly value?: string | undefined;
}

export const FormFieldTextInput: FC<FormTextInputProps> = (props) => {
    const { field, onFieldChange, value, errorMessage, required } = props;

    const [text, setText] = useState(value || "");

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setText(value);
    };

    useEffect(() => {
        onFieldChange(field.name, text);
    }, [field.name, onFieldChange, text]);

    useEffect(() => {
        setText(value || "");
    }, [value]);

    return (
        <div className={styles.xapp_text_input_container}>
            {field.title && (
                <div className={styles['xapp_text_input_container__title']}>
                    <p>{field.title}</p>
                </div>
            )}

            <div className={styles['xapp_text_input_container__field']}>
                <TextField
                    className={styles.xapp_text_input_text}
                    label={field.label}
                    fullWidth
                    margin="normal"
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={text}
                    onChange={onChange}
                    variant="filled"
                    size="small"
                    error={!!errorMessage}
                    helperText={errorMessage}
                    required={required}
                    multiline={field.multiline}
                    rows={field.rows}
                    maxRows={field.rowsMax}
                    style={field.style}
                // inputProps={{ maxLength: 10 }}
                />
            </div>
        </div>
    );
};

export default FormFieldTextInput;
