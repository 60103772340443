import React, { FC } from "react";
import styles from "./ChatButton.module.scss";

export type ChatButtonBorderStyle = {
    width?: number;
    color?: string;
};

export interface Props {
    onClick: () => void;
    addClass?: string;
    readonly borderStyle?: ChatButtonBorderStyle;
}

const ChatButton: FC<Props> = (props) => {
    return (
        <button className={`${styles.xapp_chat_button} ${props.addClass || ""}`} onClick={props.onClick}>
            <div
                id="xapp-widget-button"
                className={styles['xapp_chat_button__btn']}
                style={
                    props.borderStyle && {
                        border: "solid",
                        borderWidth: props.borderStyle.width,
                        borderColor: props.borderStyle.color,
                    }
                }>
                <svg
                    fill="#000000"
                    height="300px"
                    width="30px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 382.163 382.163">
                    <g>
                        <path
                            d="M344.89,215.855c0-50.602-24.567-95.57-62.396-123.618l2.982-5.326l46.258,25.92c1.16,0.65,2.418,0.958,3.659,0.958
		c2.626,0,5.175-1.381,6.55-3.835c8.883-15.853,11.061-34.216,6.132-51.706c-4.243-15.059-13.327-27.91-25.887-36.864l5.722-10.22
		c2.023-3.614,0.733-8.185-2.881-10.208c-3.614-2.023-8.184-0.734-10.208,2.88l-5.72,10.216
		c-31.487-13.349-68.758-1.123-85.857,29.391c-0.972,1.735-1.215,3.786-0.676,5.7s1.817,3.536,3.553,4.509l46.268,25.925
		l-2.406,4.297c-23.084-13.853-50.078-21.83-78.903-21.83c-28.825,0-55.818,7.977-78.902,21.829l-2.408-4.296l46.269-25.926
		c3.614-2.025,4.902-6.595,2.877-10.209c-8.883-15.853-23.407-27.297-40.897-32.226c-15.061-4.244-30.765-3.205-44.96,2.833
		L67.342,3.838c-2.023-3.615-6.593-4.904-10.208-2.881c-3.614,2.023-4.904,6.594-2.881,10.208l5.721,10.22
		c-12.559,8.954-21.643,21.804-25.886,36.864c-4.929,17.49-2.751,35.854,6.132,51.706c1.375,2.454,3.924,3.835,6.55,3.835
		c1.241,0,2.5-0.309,3.659-0.958l46.257-25.919l2.984,5.324c-37.83,28.047-62.397,73.016-62.397,123.619
		c0,45.21,19.61,85.924,50.767,114.091l-9.427,43.114c-0.885,4.047,1.678,8.044,5.725,8.929c0.54,0.118,1.078,0.175,1.609,0.175
		c3.448,0,6.553-2.393,7.32-5.899l7.818-35.757c25.321,18.332,56.416,29.157,89.996,29.157c33.58,0,64.676-10.825,89.997-29.158
		l7.818,35.758c0.767,3.507,3.871,5.899,7.319,5.899c0.531,0,1.07-0.057,1.609-0.175c4.046-0.885,6.61-4.882,5.725-8.929
		l-9.427-43.115C325.281,301.778,344.89,261.065,344.89,215.855z M308.517,30.436c12.357,6.924,21.279,18.246,25.121,31.88
		c3.155,11.198,2.574,22.853-1.57,33.507l-91.609-51.331C256.211,23.856,285.228,17.387,308.517,30.436z M50.096,95.824
		c-4.145-10.654-4.726-22.31-1.57-33.507c3.842-13.634,12.763-24.956,25.121-31.88c8.179-4.583,17.064-6.758,25.833-6.758
		c16.201,0,32.005,7.425,42.225,20.814L50.096,95.824z M52.273,215.855c0-76.54,62.269-138.809,138.809-138.809
		S329.89,139.315,329.89,215.855s-62.27,138.809-138.809,138.809S52.273,292.394,52.273,215.855z"
                        />
                        <path
                            d="M191.081,107.685c-59.645,0-108.17,48.525-108.17,108.17c0,59.645,48.525,108.17,108.17,108.17
		c59.646,0,108.17-48.525,108.17-108.17C299.252,156.21,250.727,107.685,191.081,107.685z M191.081,309.025
		c-51.374,0-93.17-41.796-93.17-93.17c0-51.374,41.796-93.17,93.17-93.17c51.375,0,93.17,41.796,93.17,93.17
		C284.252,267.229,242.456,309.025,191.081,309.025z"
                        />
                        <path
                            d="M198.585,190.081v-36.262c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v36.26c-8.784,2.559-15.718,9.492-18.279,18.276
		h-16.941c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16.941c3.254,11.165,13.574,19.349,25.775,19.349
		c14.805,0,26.849-12.044,26.849-26.849C217.93,203.655,209.748,193.337,198.585,190.081z M191.081,227.704
		c-6.533,0-11.849-5.315-11.849-11.849s5.315-11.849,11.849-11.849c6.534,0,11.849,5.315,11.849,11.849
		S197.615,227.704,191.081,227.704z"
                        />
                    </g>
                </svg>
            </div>
        </button>
    );
};

export default ChatButton;
