import React, { FC, useState } from "react";

import { FormDateRangeInput } from "stentor-models";

import { FormFieldProps } from "../SearchResponseFormDisplay";

export interface FormDateRangeProps extends FormFieldProps {
    readonly field: FormDateRangeInput;
}

export const FormFieldDateRange: FC<FormDateRangeProps> = (props) => {
    const { field, onFieldChange } = props;

    // TODO: Use the init values

    const [value, setValue] = useState({ from: "", to: "" });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateValue = e.target.value;

        if (e.target.id.endsWith("-from")) {
            setValue({ ...value, from: dateValue });
        } else {
            setValue({ ...value, to: dateValue });
        }

        onFieldChange(field.name, value);
        setValue(value);
    };

    // TODO: get a date range picker (material UI?)

    return (
        <div>
            <h2>{field.title}</h2>
            <input
                id={`${field.name}-from`}
                value={value.from}
                onChange={onChange}
            />
            <input
                id={`${field.name}-to`}
                value={value.to}
                onChange={onChange}
            />
        </div>
    );
};

export default FormFieldDateRange;
