import React from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { FormHeaderItem } from "stentor-models";

import styles from './SimpleNavigationHeader.module.scss';

interface SimpleNavigationHeaderProps {
    items: FormHeaderItem[];
    onHeaderClick(step: string): void;
    labelHeader?: boolean;
    stepName: string;
    isShowCase?: boolean;
}

export const SimpleNavigationHeader: React.FC<SimpleNavigationHeaderProps> = (
    props: SimpleNavigationHeaderProps
) => {
    const { items, onHeaderClick, labelHeader, stepName, isShowCase } = props;

    if (!!labelHeader) {
        const header = items.find((item) => item.step === stepName);
        const headerText = header?.label;

        return headerText !== undefined ? (
            <Box
                display="flex"
                justifyContent="center"
                gap={2}
                padding={2}
                min-height={100}
                className={!!isShowCase ? styles.xapp_navigation_header_showcase : styles.xapp_navigation_header_container}>
                <Typography variant="h4" color="white" margin={0}>
                    {headerText}
                </Typography>
                {/* <span>
                    {headerText}
                </span> */}
            </Box>
        ) : (
            <Box
                display="flex"
                justifyContent="center"
                gap={2}
                padding={2}
                min-height={100}
                className={!!isShowCase ? styles.xapp_navigation_header_showcase : styles.xapp_navigation_header_container}>
                <div className="empty_step_header"></div>
            </Box>
        );
    } else {
        return (
            <Box
                display="flex"
                justifyContent="center"
                gap={2}
                padding={2}
                min-height={100}
                className={styles.xapp_navigation_header}>
                <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    minHeight="50px"
                    sx={{
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            height: "3px",
                            backgroundColor: "white",
                            zIndex: 1,
                        },
                    }}>
                    {items.map((item, index) => (
                        <Box
                            key={index}
                            position="relative"
                            zIndex={2}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            top="10px"
                            minWidth={100}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{
                                    borderRadius: "50%",
                                    minWidth: "auto",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "3px solid white",
                                }}
                                className={styles.xapp_navigation_header}
                                onClick={() => {
                                    onHeaderClick(`${item.step}`);
                                }}
                            />
                            <Typography variant="body2" color="white" margin={1} marginTop={1}>
                                {item.label}
                            </Typography>
                            {/* <span>
                                {item.label}
                            </span> */}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
};
