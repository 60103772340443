import React, { FC } from "react";
import { SuggestionsGroupHeading } from "@xapp/chat-widget-core";
import { List } from "stentor-models";
import { Cards } from "../Cards";

export interface SearchResponseListDisplayProps {
    readonly list: List;
}

export const SearchResponseListDisplay: FC<SearchResponseListDisplayProps> = props => {
    const { list } = props;

    const title = list?.title;

    return (
        <div>
            {title ? <SuggestionsGroupHeading label={title} /> : <></>}

            <Cards list={list} />
        </div>
    );
};
