import React, { FC } from "react";
import { List } from "stentor-models";
import { Card } from "../Card";
import styles from "./Cards.module.scss";

export interface CardsProps {
    readonly list: List;
}

export const Cards: FC<CardsProps> = props => {
    const { list } = props;

    return (
        <div className={styles.xapp_search_cards}>
            {list.items.map((item, index) => (
                <Card key={index} item={item} />
            ))}
        </div>
    );
};
