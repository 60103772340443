import React, { FC, PropsWithChildren } from "react";
import styles from "./SearchResponsePart.module.scss";

export interface SearchResponsePartProps {
    extraClass?: string;
}

export const SearchResponsePart: FC<PropsWithChildren<SearchResponsePartProps>> = props => {
    const { extraClass } = props;
    return (
        <div className={`${styles.xapp_search_response_part} ${extraClass || ""}`}>
            {props.children}
        </div>
    );
};
