
/**
 * Pads the string with zeros on the left to meet
 * either length 2 or the provided length.
 *
 * If a string that is over length of 2 or the provided length,
 * it will be trimmed.
 *
 * @param {string} str
 * @param {number} [len]
 * @returns
 */
export function padZero(str: string, len = 2): string {
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
}


/**
 * Inverts a HEX color.
 *
 * Provides a color that is on the opposite side of the color wheel.
 *
 * When passed true as the second parameter, it will invert the color
 * and provide it within grey scale to help with contrast and readability.
 *
 * @source https://stackoverflow.com/a/35970186
 * @export
 * @param {string} hex
 * @param {boolean} [bw]
 * @returns
 */
export function invertColor(hex: string, bw?: boolean): string | undefined {
    if (!hex) {
        return undefined;
    }

    if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // pad each with zeros and return
    return "#" + padZero((255 - r).toString(16)) + padZero((255 - g).toString(16)) + padZero((255 - b).toString(16));
}