import React, { FC, PropsWithChildren } from "react";
import styles from "./Button.module.scss";

export interface ButtonProps {
    readonly className?: string;
    readonly expand?: boolean;
    onClick(): void;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = props => {
    const { className, expand, onClick } = props;
    return (
        <button
            type="button"
            className={`${styles.xapp_search_btn} ${expand ? styles['xapp_search_btn__expand'] : ""} ${className || ""}`}
            onClick={onClick}
        >{props.children}</button>
    );
};