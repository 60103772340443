import React, { FC, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

import { AddressAutocompleteParameters, FormFieldTextAddressInput } from "stentor-models";

import { FormTextInputProps } from "../FormFieldTextInput/FormFieldTextInput";
import { AddressAutocompleteService, PlaceType } from "../../utils/AddressAutocompleteService";

// import Popper, { PopperProps } from '@mui/material/Popper';

import styles from "./FormFieldTextInputAddress.module.scss";

// const CustomPopper = (props: JSX.IntrinsicAttributes & PopperProps & React.RefAttributes<HTMLDivElement>) => {
//     return <Popper {...props} className={styles.xapp_text_input_address_autocomplete_popper} />;
// };

let autocompleteService: AddressAutocompleteService;

export interface FormFieldTextInputAddressProps extends FormTextInputProps, Pick<FormFieldTextAddressInput, "googleMapsApiKey" | "mapsBaseUrl"> {
    readonly field: FormFieldTextAddressInput;
}

export const FormFieldTextInputAddress: FC<FormFieldTextInputAddressProps> = (props) => {
    const { field, onFieldChange, errorMessage, required, googleMapsApiKey, mapsBaseUrl } = props;

    const [address, setAddress] = useState<PlaceType | null>(null);
    const [text, setText] = useState("");
    const [options, setOptions] = useState<readonly PlaceType[]>([]);

    const onAddressChange = (a: PlaceType | null) => {
        setAddress(a);
    };

    const onTextChange = (t: string) => {
        setText(t);
    };

    const onOptionsChange = (o: readonly PlaceType[]) => {
        setOptions(o);
    };

    useEffect(() => {
        onFieldChange(field.name, text);
    }, [field.name, onFieldChange, text]);

    const apiKey = field.googleMapsApiKey || googleMapsApiKey;
    const baseUrl = field.mapsBaseUrl || mapsBaseUrl;

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string, params?: AddressAutocompleteParameters },
                    callback: (results?: readonly PlaceType[]) => void
                ) => {
                    autocompleteService.getPlacePredictions(request, callback);
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService) {
            autocompleteService = new AddressAutocompleteService({ apiKey, baseUrl });
        }

        if (text === "") {
            onOptionsChange(address ? [address] : []);
            return undefined;
        }

        const queryParams = field.mapsUrlQueryParams || undefined;

        fetch({ input: text, params: queryParams }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (address) {
                    newOptions = [address];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                onOptionsChange(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [address, text, fetch, apiKey, baseUrl, field.mapsUrlQueryParams]);

    return (
        <Autocomplete
            id="google-map-input"
            // PopperComponent={CustomPopper}
            // sx={{ width: 300 }}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={address}
            noOptionsText="No locations"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(_event: any, newValue: PlaceType | null) => {
                onOptionsChange(newValue ? [newValue, ...options] : options);
                onAddressChange(newValue);
            }}
            onInputChange={(_event, newInputValue) => {
                onTextChange(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    className={styles.xapp_text_input_address}
                    {...params}
                    variant="filled"
                    size="small"
                    margin="normal"
                    label={field.label}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    name={field.name}
                    placeholder={field.placeholder}
                    required={required}
                    fullWidth
                    style={field.style}
                />
            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    matches.map((match: any) => [match.offset, match.offset + match.length])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: "flex", width: 44 }}>
                                <LocationOnIcon sx={{ color: "text.secondary" }} />
                            </Grid>
                            <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};
